/* eslint-disable arrow-body-style */
/* eslint-disable no-loop-func */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
/* eslint-disable object-curly-newline */
/* eslint-disable react/self-closing-comp */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-array-index-key */
/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-globals */
import React, { createRef } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compareAsc } from 'date-fns';
import './css/HotelDetails.css';
import { Bars } from 'react-loader-spinner';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Helmet } from 'react-helmet';
import { initTourSearch, getSearchStatus, getToursPartial } from '../../Utils/RestUtils';
import { getCountryById, getResortsByIds } from '../../Utils/ElasticUtils';
import { getKidsText } from '../../Utils/CommonUtils';
import { CITY_OPTIONS } from '../../Utils/Constants/CitiesFrom';
import {
  changeDestination, changeDeparturetDate, setNightsFrom,
  setNightsTo, setAdults, changeDepatureCity, changeSelectedResorts,
  setKids, changeHotelDetailsFilterMenuVisibility, changeCharterOnly,
} from '../../DataStore/actions/searchFormActions';
import { HOTEL_DETAILS } from '../../Utils/Constants/GlobalConstants';
import { TOURS_NOT_FOUND, ERROR_HAPPEND } from '../../Utils/Constants/ToursResultConstants';
import { getMealFullName } from '../../Utils/DateUtil';
import SearchFormFunc from '../SearchForm/SearchFormFunc';
import leftWhiteArrow from '../../images/left-white-arrow.svg';
import rightWhiteArrow from '../../images/right-white-arrow.svg';
import GrayMoon from '../../images/gray-moon.svg';
import GrayMoonDark from '../../images/gray-moon-dark.svg';
import leftNightsArrow from '../../images/left-arrow-nights.svg';
import rightNightsArrow from '../../images/right-arrow-nights.svg';
import otherOffersArrow from '../../images/small-arrow-bottom.svg';
import HotelDescription from '../HotelDescription/HotelDescription';
import filterIco from '../../images/filter.svg';
import { HOTEL_DETAILS_TITLE } from '../../Utils/Constants/SEO';

const mapStateToProps = (state) => ({ ...state });

const reference = createRef();
// const nightsSectionRef = createRef();

class HotelDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hotel: {},
      hotelIds: [],
      selected: '',
      selectedIndex: '',
      minDayPrices: [],
      groupedByDatesTours: [],
      isDisabled: false,
      isLeftArrowVisible: false,
      isRightArrawVisible: false,
      firstDatesCarouselClisk: true,
      mealFilters: [
        { id: '112', code: 'FB', desc: 'Завтрак, обед, ужин', value: '112', checked: false, count: 0 },
        { id: '113', code: 'HB ', desc: 'Завтрак, ужин', value: '113', checked: false, count: 0 },
        { id: '114', code: 'BB', desc: 'Завтрак', value: '114', checked: false, count: 0 },
        { id: '115', code: 'AI', desc: 'Всё включено', value: '115', checked: false, count: 0 },
        { id: '116', code: 'UAI', desc: 'Всё включено ультра', value: '116', checked: false, count: 0 },
        { id: '117', code: 'RO', desc: 'Без питания', value: '117', checked: false, count: 0 },
        { id: '121', code: 'FB+', desc: 'FB ультра', value: '121', checked: false, count: 0 },
        { id: '122', code: 'HB+', desc: 'HB ультра', value: '122', checked: false, count: 0 },
        { id: '129', code: 'SC', desc: 'Самообслуживание', value: '129', checked: false, count: 0 },
      ],
      allMealsFilterSelected: true,
      isMealFilterButtonVisible: false,
      isEmptySearch: false,
      isSearchError: false,
      firstNightsCaruselRendering: false,
    };
  }

  componentDidMount() {
    const {
      location,
      depatureCity,
      destination,
      selectedResorts,
      departureDate,
      nightsFrom,
      nightsTo,
      adults,
      changeDestination: changeDestinationAction,
      changeDeparturetDate: changeDeparturetDateAction,
      setNightsFrom: setNightsFromAction,
      setNightsTo: setNightsToAction,
      setAdults: setAdultsAction,
      setKids: setKidsAction,
      changeDepatureCity: changeDepatureCityAction,
      changeSelectedResorts: changeSelectedResortsAction,
      changeCharterOnly: changeCharterOnlyAction,
      charterOnly,
    } = this.props;

    const {
      mealFilters, allMealsFilterSelected,
    } = this.state;

    const queryParameters = new URLSearchParams(location.search);

    let fromPayload;
    if (queryParameters.has('from') && queryParameters.get('from') !== '') {
      const fromId = queryParameters.get('from');

      const from = CITY_OPTIONS.filter((e) => e.id == fromId);

      if (from.length > 0) {
        changeDepatureCityAction(from[0]);
        fromPayload = from[0].id;
      }
    }

    if (!fromPayload) {
      fromPayload = depatureCity.id;
    }

    let toPayload;
    if (queryParameters.has('to') && queryParameters.get('to') !== '') {
      const to = queryParameters.get('to');
      toPayload = to;

      getCountryById(to).then((countryResult) => {
        // eslint-disable-next-line no-underscore-dangle
        const countriesArray = countryResult.data.hits.hits.map((h) => h._source);

        if (countriesArray.length > 0) {
          changeDestinationAction(countriesArray[0]);
          // eslint-disable-next-line prefer-destructuring
        }
      });
    }

    if (!toPayload) {
      toPayload = destination.id;
    }

    let cityPayload;
    if (queryParameters.has('cities') && queryParameters.get('cities') !== '') {
      const ids = queryParameters.get('cities').split(',');
      cityPayload = ids;

      if (ids.length > 0) {
        getResortsByIds(toPayload, ids).then((cityResult) => {
          // eslint-disable-next-line no-underscore-dangle
          const resorts = cityResult.data.hits.hits.map((h) => h._source);
          changeSelectedResortsAction(resorts);
        });
      }
    }

    if (!cityPayload) {
      cityPayload = selectedResorts;
    }

    let dateFromPayload;
    let dateToPayload;
    if (queryParameters.has('departureFrom') && queryParameters.get('departureFrom') !== '') {
      const dateFrom = this.formatDate(queryParameters.get('departureFrom'));

      if (dateFrom && compareAsc(dateFrom, new Date()) >= 0) {
        if (queryParameters.has('departureTo') && queryParameters.get('departureTo') !== '') {
          const dateTo = this.formatDate(queryParameters.get('departureTo'));

          if (dateTo && compareAsc(dateTo, dateFrom) >= 0) {
            changeDeparturetDateAction({ from: dateFrom, to: dateTo });
            dateFromPayload = dateFrom.toLocaleDateString('en-GB');
            dateToPayload = dateTo.toLocaleDateString('en-GB');
          }
        }
      }
    }

    if (!dateFromPayload || !dateToPayload) {
      dateFromPayload = departureDate.from.toLocaleDateString('en-GB');
      dateToPayload = departureDate.to.toLocaleDateString('en-GB');
    }

    let nightsFromPayload;
    let nightToPayload;
    if (queryParameters.has('nightsFrom') && queryParameters.get('nightsFrom') !== '') {
      let nFrom;
      let nTo;

      if (!isNaN(queryParameters.get('nightsFrom'))) {
        nFrom = parseInt(queryParameters.get('nightsFrom'), 10);

        if (nFrom > 0) {
          if (queryParameters.has('nightsTo') && queryParameters.get('nightsTo') !== '') {
            if (!isNaN(queryParameters.get('nightsTo'))) {
              nTo = parseInt(queryParameters.get('nightsTo'), 10);

              if (nFrom <= nTo) {
                setNightsFromAction(nFrom);
                setNightsToAction(nTo);

                nightsFromPayload = nFrom;
                nightToPayload = nTo;
              }
            }
          }
        }
      }
    }

    if (!nightsFromPayload) {
      nightsFromPayload = nightsFrom;
      nightToPayload = nightsTo;
    }

    let adultsPayload;
    if (queryParameters.has('adults') && queryParameters.get('adults') !== '') {
      if (!isNaN(queryParameters.get('adults'))) {
        const adultsCount = parseInt(queryParameters.get('adults'), 10);
        if (adultsCount > 0 && adultsCount <= 4) {
          setAdultsAction(adultsCount);
          adultsPayload = adultsCount;
        }
      }
    }

    if (!adultsPayload) {
      adultsPayload = adults;
    }

    const kidsPayload = [];
    if (queryParameters.has('kids') && queryParameters.get('kids') !== '') {
      const ages = queryParameters.get('kids').split(',');
      const validatedAges = [];

      ages.forEach((age, index) => {
        if (!isNaN(age)) {
          validatedAges.push({ age, text: getKidsText(parseInt(age, 10)), index });
          kidsPayload.push(age);
        }
      });

      if (validatedAges.length > 0) {
        setKidsAction(validatedAges);
      }
    }

    const mealsPayload = [];
    if (queryParameters.has('meals') && queryParameters.get('meals') !== '') {
      const meals = queryParameters.get('meals').split(',');

      meals.forEach((value) => {
        const mealsF = mealFilters.filter((e) => e.value === value);

        if (mealsF.length > 0) {
          if (allMealsFilterSelected) {
            this.setState({ allMealsFilterSelected: false });
          }
          mealsPayload.push(value);
        }
      });

      this.updateMealsFilterState(mealsPayload, true);
    }

    const hotelPayload = [];
    if (queryParameters.has('hotels') && queryParameters.get('hotels') !== '') {
      const hotels = queryParameters.get('hotels').split(',');

      hotels.forEach((hotel) => {
        if (!isNaN(hotel) && hotelPayload.length == 0) {
          hotelPayload.push(hotel);
        }
      });
    }

    this.setState({ hotelIds: hotelPayload });

    let charterPayload;
    if (queryParameters.has('charter') && queryParameters.get('charter') !== '') {
      charterPayload = queryParameters.get('charter');

      if (charterPayload === 'true') {
        changeCharterOnlyAction(true);
      } else {
        changeCharterOnlyAction(false);
      }
    }

    if (!charterPayload) {
      charterPayload = charterOnly;
    }

    if (hotelPayload.length > 0) {
      const payload = {
        departureCity: fromPayload,
        destinationCountry: toPayload,
        selectedResorts: cityPayload,
        departureDateFrom: dateFromPayload,
        departureDateTo: dateToPayload,
        nightsFrom: nightsFromPayload,
        nightsTo: nightToPayload,
        adults: adultsPayload,
        kids: kidsPayload,
        page: 1,
        stars: [],
        meals: mealsPayload,
        requestId: 0,
        sort: '',
        hotels: hotelPayload,
        charterOnly: charterPayload,
      };

      this.fetchDataLazy(payload);
    } else {
      this.setState({ isSearchError: true });
    }
  }

  componentDidUpdate() {
    const {
      selectedIndex, firstDatesCarouselClisk,
      groupedByDatesTours, firstNightsCaruselRendering,
    } = this.state;

    if (reference.current && firstDatesCarouselClisk) {
      if (reference.current.scrollWidth > reference.current.clientWidth) {
        this.setState({ firstDatesCarouselClisk: false });

        const selectedPosition = 157 * (selectedIndex + 1);
        if (selectedPosition > reference.current.clientWidth) {
          reference.current.scrollLeft += (selectedPosition + 157 - reference.current.clientWidth);
        }

        if (reference.current.scrollLeft === 0) {
          this.setState({ isLeftArrowVisible: false, isRightArrawVisible: true });
        } else if (reference.current.scrollLeft + reference.current.clientWidth
            >= reference.current.scrollWidth) {
          this.setState({ isLeftArrowVisible: true, isRightArrawVisible: false });
        } else {
          this.setState({ isLeftArrowVisible: true, isRightArrawVisible: true });
        }
      }
    }

    const groupedByDatesToursCopy = Array.from(groupedByDatesTours);

    if (groupedByDatesToursCopy.length > 0 && firstNightsCaruselRendering) {
      for (let i = 0; i < groupedByDatesToursCopy.length; i++) {
        const date = groupedByDatesToursCopy[i];
        const dateRooms = date.rooms;

        for (let j = 0; j < dateRooms.length; j++) {
          const room = dateRooms[j];
          const { ref } = room;

          if (ref && ref.current && (ref.current.scrollWidth > ref.current.clientWidth + 1)) {
            room.rightArrowVisible = true;
          }
        }
      }

      this.setState({ groupedByDatesTours: groupedByDatesToursCopy,
        firstNightsCaruselRendering: false });
    }
  }

  formatDate = (value) => {
    const date = value.split('/');
    const d = parseInt(date[0], 10);
    const m = parseInt(date[1], 10);
    const y = parseInt(date[2], 10);
    return new Date(y, m - 1, d);
  };

  handleDateClick = (id) => {
    const { selected } = this.state;

    if (selected !== id) {
      this.setState({ selected: id, firstNightsCaruselRendering: true });
    }
  };

  handleNav = (direction, ref) => {
    if (direction === 'left') {
      // eslint-disable-next-line no-unused-expressions, no-param-reassign
      ref ? (ref.current.scrollLeft -= 158) : null;

      if (ref.current.scrollLeft === 0) {
        this.setState({ isLeftArrowVisible: false });
      }

      if (ref.current.scrollLeft + ref.current.clientWidth < ref.current.scrollWidth) {
        this.setState({ isRightArrawVisible: true });
      }
    } else {
      // eslint-disable-next-line no-unused-expressions, no-param-reassign
      ref ? (ref.current.scrollLeft += 158) : null;

      if (ref.current.scrollLeft > 0) {
        this.setState({ isLeftArrowVisible: true });
      }

      if ((ref.current.scrollLeft + ref.current.clientWidth + 1) >= ref.current.scrollWidth) {
        this.setState({ isRightArrawVisible: false });
      }
    }
  };

  handleNightsCollumnNav = (direction, date, room) => {
    const { groupedByDatesTours } = this.state;

    const groupedByDatesToursCopy = Array.from(groupedByDatesTours);

    let stateRef;
    let stateRoom;
    for (let i = 0; i < groupedByDatesToursCopy.length; i++) {
      if (groupedByDatesToursCopy[i].date === date) {
        const dateRooms = groupedByDatesToursCopy[i].rooms;
        for (let j = 0; j < dateRooms.length; j++) {
          if (dateRooms[j].roomType === room.roomType) {
            stateRef = dateRooms[j].ref;
            stateRoom = dateRooms[j];
          }
        }
      }
    }

    if (direction === 'left') {
      // eslint-disable-next-line no-unused-expressions, no-param-reassign
      stateRef ? (stateRef.current.scrollLeft -= 160) : null;

      if (stateRef.current.scrollLeft === 0) {
        stateRoom.leftArrowVisible = false;
      }

      if ((stateRef.current.scrollLeft + stateRef.current.clientWidth)
        < stateRef.current.scrollWidth) {
        stateRoom.rightArrowVisible = true;
      }
    } else {
      // eslint-disable-next-line no-unused-expressions, no-param-reassign
      stateRef ? (stateRef.current.scrollLeft += 160) : null;

      if (stateRef.current.scrollLeft > 0) {
        stateRoom.leftArrowVisible = true;
      }

      if ((stateRef.current.scrollLeft + stateRef.current.clientWidth + 1)
        >= stateRef.current.scrollWidth) {
        stateRoom.rightArrowVisible = false;
      }
    }

    let leftShift = 0;
    leftShift = Math.floor(stateRef.current.scrollLeft / 160);

    let collumn = 1;
    for (let i = 0; i < stateRoom.nights.length; i++) {
      const nitghts = stateRoom.nights[i];

      if (leftShift == 0 || i + 1 > leftShift) {
        nitghts.colluntNumber = collumn;
        collumn++;
      } else {
        nitghts.colluntNumber = 0;
      }
    }

    this.setState({ groupedByDatesTours: groupedByDatesToursCopy });
  };

  buildOrderUrl = (hotel, offer) => {
    const queryParameters = new URLSearchParams();

    queryParameters.append('request', hotel.requestId);
    queryParameters.append('country', offer.countryId);
    queryParameters.append('offer', offer.tourId);
    queryParameters.append('operator', offer.operatorId);
    queryParameters.append('hotelId', hotel.hotelId);
    queryParameters.append('adults', hotel.adultsCount);
    queryParameters.append('kids', hotel.kidsCount);
    queryParameters.append('taRaiting', hotel.tripAdvisorRating);
    queryParameters.append('taReviews', hotel.tripAdvisorReviewsCount);

    return `/order?${queryParameters.toString()}`;
  };

  changeOtherOffersVisibility = (id, ref) => {
    const { groupedByDatesTours } = this.state;
    this.node = ref;
    this.mealId = id;

    const groupedByDatesToursCopy = Array.from(groupedByDatesTours);
    for (let i = 0; i < groupedByDatesToursCopy.length; i++) {
      const date = groupedByDatesTours[i];
      const dateRooms = date.rooms;
      for (let j = 0; j < dateRooms.length; j++) {
        const room = dateRooms[j];
        const roomNights = room.nights;
        for (let k = 0; k < roomNights.length; k++) {
          const nights = roomNights[k];
          const { meals } = nights;

          for (let l = 0; l < meals.length; l++) {
            const meal = meals[l];
            meal.otherOffersVisible = false;

            if (meal.id === id) {
              if (meal.otherOffersVisible) {
                meal.otherOffersVisible = false;
                document.removeEventListener('click', this.handleOutsideClick, false);
              } else {
                meal.otherOffersVisible = true;
                document.addEventListener('click', this.handleOutsideClick, false);
              }
            }
          }
        }
      }
    }

    this.setState({ groupedByDatesTours: groupedByDatesToursCopy });
  };

  handleOutsideClick = (e) => {
    const { groupedByDatesTours } = this.state;
    const elementClasses = e.target.getAttribute('class');

    if ((this.node && (this.node.contains(e.target))) || elementClasses === 'hd_other_offers'
    || elementClasses === 'hd_other_offers_arrow') {
      // eslint-disable-next-line no-useless-return
      return;
    // eslint-disable-next-line no-else-return
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);

      const groupedByDatesToursCopy = Array.from(groupedByDatesTours);
      for (let i = 0; i < groupedByDatesToursCopy.length; i++) {
        const date = groupedByDatesTours[i];
        const dateRooms = date.rooms;
        for (let j = 0; j < dateRooms.length; j++) {
          const room = dateRooms[j];
          const roomNights = room.nights;
          for (let k = 0; k < roomNights.length; k++) {
            const nights = roomNights[k];
            const { meals } = nights;

            for (let l = 0; l < meals.length; l++) {
              const meal = meals[l];

              if (meal.id === this.mealId) {
                meal.otherOffersVisible = false;
              }
            }
          }
        }
      }

      this.setState({ groupedByDatesTours: groupedByDatesToursCopy });
    }
  };

  handleFilteAppearncerButton = () => {
    const { changeHotelDetailsFilterMenuVisibility: changeHotelDetailsFilterMenuVisibilityAction,
      hotelDetailsFilterMenuVisibility } = this.props;

    changeHotelDetailsFilterMenuVisibilityAction(!hotelDetailsFilterMenuVisibility);
  };

  handleAllMeals = () => {
    const { mealFilters, allMealsFilterSelected } = this.state;

    if (!allMealsFilterSelected) {
      this.setState({ allMealsFilterSelected: true, isMealFilterButtonVisible: true });

      this.updateMealsFilterState(mealFilters.map((meal) => meal.id), false);
    }
  };

  updateMealsFilterState = (ids, value, stateCallback) => {
    const { mealFilters } = this.state;

    const newState = mealFilters.map((meal) => {
      if (ids.includes(meal.id)) {
        return { ...meal, checked: value };
      }

      return meal;
    });

    this.setState({ mealFilters: newState }, stateCallback);
  };

  handleMealsFilter = (item) => {
    this.updateMealsFilterState([item.id], !item.checked, this.mealsFilterListener);
  };

  filterMealData = () => {
    const { mealFilters } = this.state;
    const {
      changeHotelDetailsFilterMenuVisibility: changeHotelDetailsFilterMenuVisibilityAction,
    } = this.props;

    this.setState({ isMealFilterButtonVisible: false });

    this.renderQueryParams('meals', mealFilters.filter((e) => e.checked === true));

    changeHotelDetailsFilterMenuVisibilityAction(false);

    this.fetchDataLazy();
  };

  handleCharterFilter = () => {
    const { charterOnly, changeCharterOnly: changeCharterOnlyAction,
      changeHotelDetailsFilterMenuVisibility: changeHotelDetailsFilterMenuVisibilityAction,
    } = this.props;

    changeHotelDetailsFilterMenuVisibilityAction(false);
    changeCharterOnlyAction(!charterOnly);

    this.renderQueryParams('charter', [{ value: !charterOnly }]);

    this.fetchDataLazy(null, !charterOnly);
  };

  mealsFilterListener = () => {
    const { mealFilters } = this.state;

    if (mealFilters.filter((e) => e.checked === true).length === 0) {
      this.setState({ allMealsFilterSelected: true, isMealFilterButtonVisible: true });
    } else {
      this.setState({ allMealsFilterSelected: false, isMealFilterButtonVisible: true });
    }
  };

  async fetchDataLazy(payload, charter) {
    this.setState({
      isDisabled: true,
      isEmptySearch: false,
      isSearchError: false });

    let finalPayload;

    if (payload) {
      finalPayload = payload;
    } else {
      const {
        destination, depatureCity, selectedResorts, departureDate, nightsFrom,
        nightsTo, adults, kids, charterOnly,
      } = this.props;

      const {
        mealFilters,
        allMealsFilterSelected, hotelIds,
      } = this.state;

      const selectedMeals = allMealsFilterSelected
        ? []
        : mealFilters.filter((e) => e.checked === true).map((e) => e.id);

      finalPayload = {
        departureCity: depatureCity.id,
        destinationCountry: destination.id,
        selectedResorts: selectedResorts.map((item) => item.id),
        departureDateFrom: departureDate.from.toLocaleDateString('en-GB'),
        departureDateTo: departureDate.to ? departureDate.to.toLocaleDateString('en-GB') : departureDate.from.toLocaleDateString('en-GB'),
        nightsFrom,
        nightsTo,
        adults,
        kids: kids.map((kid) => kid.age),
        page: 1,
        meals: selectedMeals,
        requestId: '',
        hotels: hotelIds,
        charterOnly: charter != null ? charter : charterOnly,
      };
    }

    const requestIdResponse = await initTourSearch(finalPayload)
      .catch(() => {
        this.setState({ isSearchError: true, isDisabled: false });
      });

    if (requestIdResponse && requestIdResponse.data) {
      const requestId = requestIdResponse.data;

      finalPayload.requestId = requestId;

      let processedCount = 0;
      let resultProcessedCount = 0;
      let operatorsCount = 100;
      let isEmptyResult = true;

      let isErr = false;
      let counter = 0;
      while (processedCount !== operatorsCount) {
        counter++;
        if (counter <= 5) {
          await this.timeout(1000);
        } else {
          await this.timeout(2000);
        }

        const statusRespoinse = await getSearchStatus(requestId).catch(() => {
          if (counter >= 5) {
            isErr = true;
          }
        });

        if (isErr) {
          this.setState({ isSearchError: true, isDisabled: false });
          break;
        }

        if (statusRespoinse && statusRespoinse.data) {
          operatorsCount = statusRespoinse.data.filter((e) => e.error === false).length;
          resultProcessedCount = statusRespoinse.data
            .filter((e) => e.processed === true && e.error === false).length;

          if (resultProcessedCount > processedCount) {
            const toursResult = await getToursPartial(finalPayload);

            if (toursResult && toursResult.data) {
              if (toursResult.data.hotels.length > 0) {
                this.fillData(toursResult.data);

                isEmptyResult = false;
                this.setState({ isDisabled: false });
              }
            }

            processedCount = resultProcessedCount;
          }
        }
      }

      if (isEmptyResult && !isErr) {
        this.setState({
          groupedByDatesTours: [],
          isEmptySearch: true,
        });
      }

      this.setState({ isDisabled: false });
    }
  }

  async timeout(delay) {
    return new Promise((res) => { setTimeout(res, delay); });
  }

  fillData(result) {
    const { hotels, minDayPrices, groupedByDatesTours } = result;
    const hotel = hotels[0];

    let minPrice = minDayPrices[0].dayMinPrice;
    let selected = 'date-0';
    let selectedIndex = 0;

    minDayPrices.forEach((dayPrice, index) => {
      if (minPrice > dayPrice.dayMinPrice) {
        minPrice = dayPrice.dayMinPrice;
        selected = `date-${index}`;
        selectedIndex = index;
      }
    });

    for (let i = 0; i < groupedByDatesTours.length; i++) {
      const date = groupedByDatesTours[i];
      const dateRooms = date.rooms;
      for (let j = 0; j < dateRooms.length; j++) {
        const room = dateRooms[j];
        room.ref = createRef();
        room.rightArrowVisible = false;
        room.leftArrowVisible = false;

        const roomNights = room.nights;
        for (let k = 0; k < roomNights.length; k++) {
          const nights = roomNights[k];
          nights.colluntNumber = k + 1;

          const { meals } = nights;

          for (let l = 0; l < meals.length; l++) {
            const meal = meals[l];
            meal.otherOffersVisible = false;
            meal.id = `${date.date}-${room.roomType}-${nights.nightsCount}-${meal.meal}`;
          }
        }
      }
    }

    this.setState({
      hotel,
      selected,
      selectedIndex,
      minDayPrices,
      groupedByDatesTours,
      firstNightsCaruselRendering: true,
      firstDatesCarouselClisk: true,
      isLeftArrowVisible: false,
      isRightArrawVisible: false,
    });
  }

  renderDates = (dates) => dates.map((dayMinPrice, index) => {
    const id = `date-${index}`;
    const { selected } = this.state;

    return (
      <button
        key={`hd-tors-price-${index}`}
        type="button"
        onClick={() => this.handleDateClick(id)}
      >
        <div className={`hd-tour-date-yellow-border ${selected === id ? 'hd-tour-date-yellow-border-selected' : ''}`}></div>
        <div className={`hd-tour-date-w ${selected === id ? 'hd-tour-date-w-selected' : ''}`}>
          <div className="hd-tour-date-border">
            <div className={`hd-tour-date ${selected === id ? 'hd-tour-date-selected' : ''}`}>{dayMinPrice.formattedDate}</div>
            <div className={`hd-tour-date-price ${selected === id ? 'hd-tour-date-price-selected' : ''}`}>
              <div>от&nbsp;</div>
              <div className={`hd-tour-date-price-curr ${selected === id ? 'hd-tour-date-price-curr-selected' : ''}`}>
                {`${dayMinPrice.dayMinPrice} ${dayMinPrice.currency}`}
              </div>
            </div>
          </div>
        </div>
      </button>
    );
  });

  renderDayTours = (dates) => dates.map((date, index) => {
    const id = `date-${index}`;
    const { selected } = this.state;
    return (
      <div key={id} className={`hd-offers-w ${selected === id ? 'offer_details_selected' : ''}`}>
        {this.renderDayRooms(date.date, date.rooms)}
      </div>
    );
  });

  renderDayRooms = (date, rooms) => rooms.map((room) => {
    return (
      <div key={`room-w-${date}-${room.roomType}`} className="hd_day_room_w">
        <div className="hd-room-type">
          {room.roomType}
        </div>
        <div key={`room--${date}-${room.roomType}`} className="hd_nights_line_w">
          <button
            type="button"
            className={`hd_nights_line_button hd_nights_button_left ${room.leftArrowVisible ? '' : 'hd_invisible'}`}
            onClick={() => this.handleNightsCollumnNav('left', date, room)}
          >
            <img src={leftNightsArrow} alt="" />
          </button>
          <div className="hd_nights_wrapper" ref={room.ref}>
            {this.renderRoomsNights(date, room.roomType, room.nights)}
          </div>
          <button
            type="button"
            className={`hd_nights_line_button hd_nights_button_right ${room.rightArrowVisible ? '' : 'hd_invisible'}`}
            onClick={() => this.handleNightsCollumnNav('right', date, room)}
          >
            <img src={rightNightsArrow} alt="" />
          </button>
        </div>
      </div>
    );
  });

  renderRoomsNights = (date, roomType, nights) => nights.map((night) => {
    return (
      <div key={`nights-${date}-${roomType}-${night.nightsCount}`} className="hd_night_collumn">
        <div className="hd_nights_count_w">
          <div className="hd_nights_count">{night.nightsCount}</div>
          <img className="hd_gray_moon" src={GrayMoon} alt="" />
        </div>
        <div className="hd_date_to">{`до ${night.dateToFormatted}`}</div>
        {this.renderNightsMeals(
          date,
          roomType,
          night.nightsCount,
          night.meals,
          night.colluntNumber,
        )}
      </div>
    );
  });

  renderNightsMeals = (
    date,
    roomType,
    nightsCount,
    meals,
    colluntNumber,
  ) => meals.map((meal, index) => {
    let other = 0;
    let otherTours;
    if (meal.tours.length > 1) {
      if (meal.tours.length > 10) {
        otherTours = meal.tours.slice(0, 10);
      } else {
        otherTours = meal.tours;
      }

      other = otherTours.length - 1;
    }

    let word;
    if (other <= 1) {
      word = 'предложение';
    } else if (other <= 4) {
      word = 'предложения';
    } else {
      word = 'предложений';
    }

    let otherOffersRef;

    return (
      <div key={`meal-${date}-${roomType}-${nightsCount}-${index}-${meal.meal}`} className="hd_meals_w">
        {meal.meal && (
          <div className="hd_meals_wr">
            <div className="hd_meal_top_line"></div>
            <div className="hd_meal_name">{`${meal.meal} ${getMealFullName(meal.meal)}`}</div>
            {meal.tours[0] && this.renderMealsPrice(meal.tours[0])}
            {other != 0 && (
              <div className="hd_other_offer_wrapper">
                <button type="button" className="hd_other_offers" onClick={() => this.changeOtherOffersVisibility(meal.id, otherOffersRef)}>
                  {`еще ${other} ${word} `}
                  <img className="hd_other_offers_arrow" src={otherOffersArrow} alt="" />
                </button>
                <div ref={(node) => { otherOffersRef = node; }} className={`hd_other_offers_w ${meal.otherOffersVisible ? '' : 'hd_invisible'} hd_nights_collumn_${colluntNumber}`}>
                  {this.renderOtherOffers(otherTours.slice(1))}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  });

  renderOtherOffers = (tours) => tours.map((tour) => {
    const { hotel } = this.state;

    return (
      <div key={`tour-${tour.tourId}`} className="hd_ather_offer_item_w">
        <div className="hd_other_offer_button">
          <Link className="hd_other_offer_link" target="_blank" to={this.buildOrderUrl(hotel, tour)}>
            <div className="hd_ather_offer_item">
              <div className="hd_ather_offer_item_operator">{tour.operator}</div>
              <div>
                <div className="hd-tour-price-other-offer">{`${tour.tourPrice} ${tour.currency}`}</div>
                {(tour.bynPrice > 0)
                  && <div className="hd_byn_price_other">{`${tour.bynPrice} BYN`}</div>}
              </div>
            </div>
          </Link>
        </div>
      </div>
    );
  });

  renderMealsPrice = ((tour) => {
    const { hotel } = this.state;
    return (
      <div className="hd_meal_price_w">
        <div className="hd-tour-button">
          <Link target="_blank" className="hd-tour-link" to={this.buildOrderUrl(hotel, tour)}>
            <div className="hd-tour-price-offer">{`${tour.tourPrice} ${tour.currency}`}</div>
            {tour.bynPrice > 0
              && <div className="hd_byn_price">{`${tour.bynPrice} BYN`}</div>}
          </Link>
        </div>
        <div className="hd-tour-price-per-night-w">
          <div className="hd-tour-price-per-night">{`${tour.pricePerNight} ${tour.currency}/`}</div>
          <img className="hd-tour-price-per-night-mon" src={GrayMoonDark} alt="" />
        </div>
        <div className="hd-tour-operator">{tour.operator}</div>
      </div>
    );
  });

  renderMealFilters = (meals) => meals.map((item) => {
    return (
      <div key={item.id}>
        <label htmlFor={item.id} className="checkbox_container filters_container">
          <input
            id={item.id}
            type="checkbox"
            checked={item.checked}
            onChange={() => this.handleMealsFilter(item)}
          />
          <span className="checkmark" />
          <div className="filter_description">
            <span className="tr_meals_code">{item.code}</span>
            {item.desc}
          </div>
        </label>
      </div>
    );
  });

  renderQueryParams = (param, filter) => {
    const { navigate, location } = this.props;
    const queryParameters = new URLSearchParams(location.search);

    queryParameters.delete(param);

    const queryParamsValues = [];
    filter.forEach((i) => {
      queryParamsValues.push(i.value);
    });

    if (queryParamsValues.length > 0) {
      queryParameters.append(param, queryParamsValues);
    }

    navigate(
      {
        pathname: '/hotel',
        search: queryParameters.toString(),
      },
    );
  };

  render() {
    const {
      hotel, isDisabled, isLeftArrowVisible,
      isRightArrawVisible, minDayPrices, groupedByDatesTours,
      isEmptySearch, isSearchError, allMealsFilterSelected,
      isMealFilterButtonVisible, mealFilters,
    } = this.state;

    console.log('groupedByDatesTours', groupedByDatesTours);

    const { hotelDetailsFilterMenuVisibility, charterOnly } = this.props;
    return (
      <div>
        <Helmet>
          <title>{`${HOTEL_DETAILS_TITLE} ${hotel.hotelName ? hotel.hotelName : ''}`}</title>
        </Helmet>
        <div className="hotel_intro">
          <div className="container">
            <div className="intro__inner">
              <SearchFormFunc
                page={HOTEL_DETAILS}
                isDisabled={isDisabled}
                search={() => this.fetchDataLazy()}
                hotel={hotel}
              />
            </div>
          </div>
          {/* eslint-disable-next-line react/self-closing-comp */}
          <div className="hotel_intro__wave"></div>
        </div>
        <div className="content">
          <div className="hd_wrapper container">
            <div className={(isDisabled || isSearchError) ? 'disabledContent' : ''}>
              <HotelDescription hotel={hotel} />
              <div className="hd_tours_wrapper">
                <div className="hd_header_wrapper">
                  <h1 className="hd_tours_hotel_name">{`${hotel.hotelName} ${hotel.hotelCategory} *`}</h1>
                  <div className="hd_dates_carousel_wrapper">
                    <button type="button" className={`hd_scroll_arrow hd_scroll_arrow_left ${isLeftArrowVisible ? '' : 'hd_arrow_invisible'}`} onClick={() => this.handleNav('left', reference)}>
                      <img src={leftWhiteArrow} alt="" />
                    </button>
                    <div className="hd_tours_carousel_scroll_w" ref={reference}>
                      {this.renderDates(minDayPrices)}
                    </div>
                    <button type="button" className={`hd_scroll_arrow hd_scroll_arrow_right ${isRightArrawVisible ? '' : 'hd_arrow_invisible'}`} onClick={() => this.handleNav('right', reference)}>
                      <img src={rightWhiteArrow} alt="" />
                    </button>
                  </div>
                </div>
                <button type="button" onClick={this.handleFilteAppearncerButton} className="hd_tablet_filter_buttom">
                  <div className="hd_tablet_filter_buttom_text_w">
                    <div className="hd_tablet_filter_buttom_text">Фильтры</div>
                    <img alt="" src={filterIco} />
                  </div>
                </button>
                <div className="hd_offers_wrapper">
                  {/* Filters bar */}
                  <div className={`hd_left_bar_wrapper text_16_400 ${hotelDetailsFilterMenuVisibility ? '' : 'hd_invisible'}`}>
                    <div className="hd_left_bar">
                      <div className="hd_filter_item">
                        <div className="hotel_class_title">Рейс</div>
                        <div key="charterOnly">
                          <label htmlFor="charterOnly" className="checkbox_container filters_container">
                            <input
                              id="charterOnly"
                              type="checkbox"
                              onChange={this.handleCharterFilter}
                              checked={charterOnly}
                            />
                            {/* eslint-disable-next-line react/self-closing-comp */}
                            <span className="checkmark"></span>
                            <div className="filter_description">Только чартер</div>
                          </label>
                        </div>
                      </div>
                      <div className="hd_filter_item">
                        <div className="hotel_class_title">Питание</div>
                        <label htmlFor="anyFood" className="checkbox_container filters_container">
                          <input
                            id="anyFood"
                            type="checkbox"
                            onChange={this.handleAllMeals}
                            checked={allMealsFilterSelected}
                          />
                          <span className="checkmark" />
                          <div className="filter_description">
                            Любое
                          </div>
                        </label>
                        {this.renderMealFilters(mealFilters)}
                        <button
                          type="button"
                          className={isMealFilterButtonVisible ? 'btn__blue tr_filter_button' : 'btn__blue tr_filter_button tr_invisible'}
                          onClick={this.filterMealData}
                        >
                          Показать
                        </button>
                      </div>
                    </div>
                  </div>
                  {isEmptySearch && (
                    <div className="tr_not_found">{TOURS_NOT_FOUND}</div>
                  )}
                  {isSearchError && (
                    <div className="tr_not_found">{ERROR_HAPPEND}</div>
                  )}
                  {this.renderDayTours(groupedByDatesTours)}
                </div>
              </div>
            </div>
            <div className={`hd_spinner_wrapper ${isDisabled ? '' : 'hd_invisible'}`}>
              <div>
                <Bars
                  height="40"
                  width="40"
                  color="#00BFFF"
                  ariaLabel="bars-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={isDisabled}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

HotelDetails.propTypes = {
  location: PropTypes.instanceOf(Object).isRequired,
  kids: PropTypes.instanceOf(Array).isRequired,
  depatureCity: PropTypes.instanceOf(Object).isRequired,
  destination: PropTypes.instanceOf(Object).isRequired,
  selectedResorts: PropTypes.instanceOf(Array).isRequired,
  departureDate: PropTypes.instanceOf(Object).isRequired,
  nightsFrom: PropTypes.number.isRequired,
  nightsTo: PropTypes.number.isRequired,
  adults: PropTypes.number.isRequired,
  changeDeparturetDate: PropTypes.func.isRequired,
  setNightsFrom: PropTypes.func.isRequired,
  setNightsTo: PropTypes.func.isRequired,
  setAdults: PropTypes.func.isRequired,
  setKids: PropTypes.func.isRequired,
  changeDestination: PropTypes.func.isRequired,
  changeDepatureCity: PropTypes.func.isRequired,
  changeSelectedResorts: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
  changeHotelDetailsFilterMenuVisibility: PropTypes.func.isRequired,
  hotelDetailsFilterMenuVisibility: PropTypes.bool.isRequired,
  charterOnly: PropTypes.bool.isRequired,
  changeCharterOnly: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  changeDestination,
  changeDeparturetDate,
  setNightsFrom,
  setNightsTo,
  setAdults,
  setKids,
  changeDepatureCity,
  changeSelectedResorts,
  changeHotelDetailsFilterMenuVisibility,
  changeCharterOnly,
})(HotelDetails);
