/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
/* eslint-disable no-trailing-spaces */
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Helmet } from 'react-helmet';
import { EGYPT_TITLE, EGYPT_DESCRIPTION } from '../../../Utils/Constants/SEO';
import { EGYPT_COUNTRY_ID } from '../../../Utils/Constants/GlobalConstants';
import ToursResultFunc from '../../ToursResult/ToursResultFunc';

class Egypt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getText = () => (
    <div>
      <div className="content_block">
        <h1 className="text_h1">Туры в Египет из Минска</h1>
        <p className="text_p">
          Египет — это одна из самых привлекательных стран для белорусских туристов благодаря прекрасному морю и всесезонности отдыха. Туры в Египет из Минска пользуются большим спросом, что неудивительно: эта страна предлагает уникальные возможности для отдыха и изучения истории. В этой статье мы рассмотрим, почему стоит посетить Египет, какие курорты выбрать и как организовать своё путешествие.
        </p>
      </div>
      <div className="content_block">
        <h2 className="text_h2">Почему стоит выбрать Египет?</h2>
        <p className="text_p">
          1. Историческое наследие: Египет — это родина многих древних цивилизаций. Пирамиды, храмы и гробницы — всё это не оставит равнодушным ни одного туриста. Обязательно посетите пирамиды в Гизе и Долину царей.
        </p>
        <p className="text_p">
          2. Прекрасные курорты: Египет предлагает разнообразные варианты для пляжного отдыха: <b>Хургада</b> - один из самых популярных курортов, знаменитый своими пляжами. Идеально подходит для семейного отдыха. <b>Шарм-эль-Шейхславится</b> своими коралловыми рифами и отличными условиями для дайвинга. 
        </p>
        <p className="text_p">
          3. Разнообразие активностей: Помимо пляжного отдыха, в Египте можно заняться сноркелингом, дайвингом, посетить экскурсии по историческим местам, а также насладиться невероятной египетской кухней.
        </p>
        <p className="text_p">
          4. Климат: Даже в зимние месяцы температура воздуха в Египте остаётся комфортной, что делает его идеальным направлением для отдыха в любое время года.
        </p>
      </div>
      <div className="content_block">
        <h2 className="text_h2">Как выбрать тур из Минска?</h2>
        <p className="text_p">
          Определитесь с длительностью путешествия, датами и составом туристов. Отметьте нужные параметры в строке поиска - выбирайте из предложенных вариантов.
        </p>
        <p className="text_p">
          2. Многие туроператоры предлагают скидки на раннее бронирование. Планируя отдых заранее, вы можете выбрать отели разных классов — от бюджетных до люксовых.
        </p>
        <p className="text_p">
          3. Экскурсии и развлечения: Постарайтесь заранее определиться, какие экскурсии вы хотите посетить. Многие отели предлагают своим гостям организованные поездки, но можно также рассмотреть возможность самостоятельного планирования.
        </p>
        <p className="text_p">
          4.  Для поездки в Египет необходимо наличие паспорта, срок действия которого должен быть не менее 6 месяцев после даты въезда в Египет. Граждане Беларуси, прилетающие в Египет, могут получить визу в аэропорту по прилету за 25$. Прилетающие в а Шарм-эль-Шейх могут бесплатно получить "синайский штамп", который дает право нахождения на территории Синайского полуострова до 14 дней.
        </p>
      </div>
    </div>
  );

  render() {
    const text = this.getText();
    return (
      <div className="content">
        <Helmet>
          <title>{EGYPT_TITLE}</title>
          <meta name="description" content={EGYPT_DESCRIPTION} />

          <meta property="og:site_name" content="TourHunter.by" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://tourhunter.by/egypt/" />
          <meta property="og:title" content={EGYPT_TITLE} />
          <meta property="og:description" content={EGYPT_DESCRIPTION} />
          {/* <meta property="og:image" content="https://tourhunter.by/file/turkey.jpg" /> */}

          <link rel="canonical" href="https://tourhunter.by/egypt/" />
        </Helmet>
        <ToursResultFunc countryFilter={EGYPT_COUNTRY_ID} text={text} />
      </div>
    );
  }
}

export default Egypt;
