/* eslint-disable class-methods-use-this */
/* eslint-disable max-len */
/* eslint-disable no-trailing-spaces */
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Helmet } from 'react-helmet';
import { UAE_TITLE, UAE_DESCRIPTION } from '../../../Utils/Constants/SEO';
import { UAE_COUNTRY_ID } from '../../../Utils/Constants/GlobalConstants';
import ToursResultFunc from '../../ToursResult/ToursResultFunc';

class UAE extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getText = () => (
    <div>
      <div className="content_block">
        <h1 className="text_h1">Туры в ОАЭ из Минска</h1>
        <p className="text_p">
          Объединенные Арабские Эмираты – это одно из самых уникальных направлений для туристов со всего мира и туристический рай для самых искушенных путешественников.  Если вы планируете поездку в ОАЭ, то эта статья именно для вас!
        </p>
      </div>
      <div className="content_block">
        <h2 className="text_h2">Почему стоит выбрать ОАЭ?</h2>
        <p className="text_p">
          1. Разнообразный отдых. ОАЭ предлагает множество вариантов досуга: от пляжного отдыха в таких курортах, как Дубай и Абу-Даби, до активных развлечений, включая сафари на джипах по пустыне, снорклинг у коралловых рифов, экскурсии в горы Хаджар и многое другое.
        </p>
        <p className="text_p">
          2. Современность и культура. Городские чудеса, такие как Бурдж-Халифа, Лувр Абу-Даби и Пальма Джумейра, соседствуют с традиционными базарами и мечетями, где можно окунуться в атмосферу восточной культуры.
        </p>
        <p className="text_p">
          3. Климат. Как правило, климат в ОАЭ теплый и солнечный, что позволяет наслаждаться пляжем и активными видами спорта круглый год.
        </p>
      </div>
      <div className="content_block">
        <h2 className="text_h2">Как купить тур в ОАЭ из Минска на нашем сайте?</h2>
        <div className="text_p">
          1. Определитесь с длительностью путешествия, датами и составом туристов. Отметьте нужные параметры в строке поиска - выбирайте из предложенных вариантов.
        </div>
        <div className="text_p">
          2. Выберите необходимый эмират. Для пляжного отдыха отлично подойдет Фуджейра, с теплым, но освежающим океаном. Если хотите совместить пляжный отдых с экскурсиями - выбирайте Дубай или комби тур.
        </div>
        <div className="text_p">
          3. При выборе тура стоит учитывать не только стоимость самого пакета, но и дополнительные расходы на экскурсии, питание и покупки. ОАЭ предлагает широкий выбор как роскошных, так и более доступных вариантов.
        </div>
      </div>
      <div className="content_block">
        <h2 className="text_h2">Популярные туристические направления в ОАЭ</h2>
        <div className="text_p">
          Дубай. Этот город славится своими невероятными небоскребами, шопингом, аттракционами и пляжами. Посетите Дубай Молл, прогуляйтесь по набережной и насладитесь вечерним шоу фонтанов.
        </div>
        <div className="text_p">
          Абу-Даби. Столица ОАЭ предлагает путешественникам насладиться культурными достопримечательностями, такими как мечеть Шейха Зайды, и активным отдыхом в парке развлечений «Ferrari World».
        </div>
        <div className="text_p">
          Шарджа. Этот эмират известен своим традиционным укладом жизни, культурой и искусством. Здесь расположены несколько интересных музеев и культурных центров.
        </div>
        <div className="text_p">
          Фуджейра. Отличное место для любителей пляжного отдыха и водных видов спорта, с потрясающими пляжами и горными пейзажами.
        </div>
      </div>
      <div className="content_block">
        <h2 className="text_h2">Полезные советы</h2>
        <div className="text_p">
          Лучшее время для посещения ОАЭ – с октября по апрель, когда погода более комфортна для активного отдыха.
        </div>
        <div>
          Уважайте местные традиции и обычаи. Например, в общественных местах рекомендуется носить одежду, закрывающую плечи и колени.
        </div>
        <div>
          Для въезда на территорию ОАЭ необходимо наличие паспорта, сроком действия не менее 6 месяцев с даты начала тура. 
        </div>
        <div>
          Определиться с вашим идеальным маршрутом помогут наши грамотные менеджеры!
        </div>
      </div>
    </div>
  );

  render() {
    const text = this.getText();

    return (
      <div className="content">
        <Helmet>
          <title>{UAE_TITLE}</title>
          <meta name="description" content={UAE_DESCRIPTION} />

          <meta property="og:site_name" content="TourHunter.by" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://tourhunter.by/uae/" />
          <meta property="og:title" content={UAE_TITLE} />
          <meta property="og:description" content={UAE_DESCRIPTION} />
          {/* <meta property="og:image" content="https://tourhunter.by/file/turkey.jpg" /> */}

          <link rel="canonical" href="https://tourhunter.by/uae/" />
        </Helmet>
        <ToursResultFunc countryFilter={UAE_COUNTRY_ID} text={text} />
      </div>
    );
  }
}

export default UAE;
