/* eslint-disable operator-linebreak */
// Home page
export const HOME_PAGE_TITLE =
  'Подбор тура по всем туроператорам: сравни предложения и выбери лучшее!';
export const HOME_PAGE_META_DESCRIPTION =
  'TourHunter - сервис поиска туров онлайн по всем туроператорам✈ Купить тур по цене туроператора или ниже✔️ без посещения офиса✔️ Все документы на почту';
export const HOME_PAGE_IMAGE_ALT = 'Подбор тура по всем туроператорам';

// About
export const ABOUT_TITLE = 'О нас';
export const ABOUT_DESCRIPTION =
  'Покупка онлайн сэкономит ваши самые ценные ресурсы время и деньги: так как вы можете забронировать тур из любого места в любое время без посещения агентства и звонка по телефону.';

// Contacts
export const CONTACTS_TITLE = 'Контакты';
export const CONTACTS_DESCRIPTION =
  'На этой странице вы найдете информацию о комнании TourHunter.by';

// FAQ
export const FAQ_TITLE = 'Часто задаваемые вопросы';
export const FAQ_DESCRIPTION =
  'На этой странице вы найдете ответы на часто задаваемые вопросы';

// Tury is Minska
export const TURY_MINKS_TITLE =
  'Туры из минска от всех туроператоров онлайн: ваш идеальный отдых в один клик!';
export const TURY_MINKS_DESCRIPTION =
  'Туры из Минска на море 2025 – самые выгодные цены и предложения от всех туроператоров✔️ Забронируйте свой идеальный отпуск';

// Podbor
export const PODBOR_TITLE =
  'Подбор тура по всем туроператорам онлайн: сравни предложения и выбери лучшее!';
export const PODBOR_DESCRIPTION =
  'TourHunter - сервис бронирования туров онлайн✔️ На нашей платформе вы можете легко сравнить туры от всех ведущих туроператоров✔️ без посещения офиса✔️';

// Tyrkey
export const TURKEY_TITLE = 'Туры в Турцию из Минск. Актуальные цены 2025';
export const TURKEY_DESCRIPTION =
  'Туры в Турцию 2025✔️ Узнайте о лучших предложениях и горящих турах из Минска✔️ Планируйте свой идеальный отдых уже сегодня!';

// Egypt
export const EGYPT_TITLE = 'Туры в Египет из Минска. Актуальные цены 2025';
export const EGYPT_DESCRIPTION =
  'Подбор тура в Египет из Минска в пару кликов! Мы предлагаем лучшие предложения для вашего идеального отдыха';

// Georgia
export const GEORGIA_TITLE = 'Туры в Гурзию из Минска. Актуальные цены 2025';
export const GEORGIA_DESCRIPTION =
  'Туры в Грузию на самолете из Минска: идеальный отдых в пару кликов✔️';

// Georgia
export const UAE_TITLE = 'Туры в ОАЭ из Минска. Актуальные цены 2025';
export const UAE_DESCRIPTION =
  'Тур в ОАЭ из Минска 2025✔️ лучшие цены и горящие предложения!';

export const HOTEL_DETAILS_TITLE = 'Отель';
export const ORDER_DETAILS_TITLE = 'Тур';
export const CHECKOUT_TITLE = 'Оформление заказа';
