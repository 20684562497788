import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import './css/Header.css';
import {
  ABOUT_US, CONTACTS, PHONE_COMMENT, LOG_IN,
} from '../../Utils/Constants/HeaderConstats';
import { PHONE_NUMBER } from '../../Utils/Constants/GlobalConstants';
import Logo from '../../images/tourhanter_logo.png';

import UserMenuFunc from './UserMenu/UserMenuFunc';
import { changeLoginModalVisibility } from '../../DataStore/actions/modalWindowActions';

const mapStateToProps = (state) => ({ ...state });

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);

    this.state = {
      isMenuVisible: false,
    };
  }

  handleClick() {
    const { isMenuVisible } = this.state;
    if (!isMenuVisible) {
      // attach/remove event handler
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }

    this.setState((prevState) => ({
      isMenuVisible: !prevState.isMenuVisible,
    }));
  }

  handleOutsideClick(e) {
    // ignore clicks on the component itself
    if (e.target.className === 'login__btn no_border' || e.target.className === 'h-user-name') {
      return;
    }
    this.handleClick();
  }

  render() {
    const {
      changeLoginModalVisibility: changeLoginModalVisibilityAction,
      isUserLoggedIn,
    } = this.props;

    const { isMenuVisible } = this.state;
    const email = window.localStorage.getItem('email');
    return (
      <header className="header">
        <div className="container">
          <div className="header__inner">
            <div className="header__logo">
              <Link to="/"><img className="header_logo_img" src={Logo} alt="" /></Link>
              <div className="nav">
                <Link to="/about/" className="nav__link">{ABOUT_US}</Link>
                <Link to="/contacts/" className="nav__link">{CONTACTS}</Link>
              </div>
            </div>
            {/* <div className="header__middle">
              <Link to="#" className="header__location">{LOCATION}</Link>
              <div className="phone">
                <div className="phone__wrapper">
                  <div className="phone__number">{PHONE_NUMBER}</div>
                  <div className="phone__text">{PHONE_COMMENT}</div>
                </div>
              </div>
              <Link to="#" className="btn btn__yellow">{BUY_IN_OFFICE}</Link>
              <button type="button" className="burger">
                <span className="burger__item">Menu</span>
              </button>
            </div> */}
            <div className="header__middle">
              <div className="phone">
                <div className="phone__wrapper">
                  <div className="phone__number">{PHONE_NUMBER}</div>
                  <div className="phone__text">{PHONE_COMMENT}</div>
                </div>
              </div>
              { isUserLoggedIn
                ? (
                  <div className="loginWrapper">
                    <button type="button" onClick={this.handleClick} className="login__btn no_border">
                      <div className="h-user-name">{email}</div>
                    </button>
                    {isMenuVisible && (
                      <div className="userMenu">
                        <UserMenuFunc />
                      </div>
                    )}
                  </div>
                )
                : <button type="button" onClick={() => changeLoginModalVisibilityAction(true)} className="login__btn">{LOG_IN}</button> }
            </div>
          </div>
        </div>
      </header>
    );
  }
}

Header.propTypes = {
  changeLoginModalVisibility: PropTypes.func.isRequired,
  isUserLoggedIn: PropTypes.bool.isRequired,
};

export default connect(
  mapStateToProps,
  { changeLoginModalVisibility },
)(Header);
