/* eslint-disable class-methods-use-this */
/* eslint-disable max-len */
/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable no-trailing-spaces */
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Helmet } from 'react-helmet';
import { TURKEY_TITLE, TURKEY_DESCRIPTION } from '../../../Utils/Constants/SEO';
import { TURKEY_ID } from '../../../Utils/Constants/GlobalConstants';
import ToursResultFunc from '../../ToursResult/ToursResultFunc';

class Turkey extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getText = () => (
    <div>
      <div className="content_block">
        <h1 className="text_h1">Туры в Турцию из Минска: поиск по всем туроператорам</h1>
        <p className="text_p">
          Первое, что приходит на ум, когда вы планируете летний отдых? Конечно, Турция! Поездка в Турцию — это замечательная возможность насладиться прекрасным климатом, великолепной кухней и уникальной культурой. В этой статье мы расскажем, почему стоит выбрать туры в Турцию из Минска и как организовать свой идеальный отпуск.
        </p>
      </div>
      <div className="content_block">
        <h2 className="text_h2">Доступные направления</h2>
        <p className="text_p">
          Современные поездки в Турцию из Минска становятся все более популярными. Благодаря разнообразию предложений от туроператоров, вы можете выбрать как стандартные пакеты «все включено», так и индивидуальные туры с экскурсиями. Наиболее популярные направления для отдыха — это Анталия, Стамбул и Бодрум. Каждый из этих городов предлагает свой уникальный опыт: от пляжного отдыха до экскурсионного туризма.
        </p>
      </div>
      <div className="content_block">
        <h2 className="text_h2">Сочетание цены и качества</h2>
        <p className="text_p">
          Турция известна своими выгодными ценами на отдых. Здесь вы можете насладиться качественным сервисом и комфортными условиями проживания без чрезмерных затрат. При бронировании туров через наш сайт вы можете словить выгодные предложения, такие как скидки на раннее бронирование или горящие туры.
        </p>
      </div>
      <div className="content_block">
        <h2 className="text_h2">Разнообразие активностей</h2>
        <p className="text_p">
          Отдых в Турции не ограничивается только пляжем и купанием в море. Страна богата историческими памятниками, живописными ландшафтами и уникальными культурными традициями. После бронирования тура обратитесь к нашим менеджерам - они помогут подобрать экскурсии по древним городам, таким как Эфес или Памуккале, или расскажут, как насладиться природными красотами, как например, поездка на парусной яхте по лазурным бухтам. Не забудьте про гастрономические туры — попробовать местную кухню просто необходимо!
        </p>
      </div>
      <div className="content_block">
        <h2 className="text_h2">Комфортный перелет и хорошая инфраструктура</h2>
        <p className="text_p">
          Прямой перелет сделает ваше путешествие комфортным, а перекус на борту не даст проголодаться. Если путешествуете с детьми - не забудьте приготовить с собой развивающие игры и раскраски.
        </p>
      </div>
      <div className="content_block">
        <h2 className="text_h2">Как выбрать тур </h2>
        <p className="text_p">
          При планировании тура в Турцию из Минска стоит учитывать следующие моменты:
        </p>
        <p className="text_p">
          Период поездк: Летний сезон — самый популярный, но весна и осень также прекрасно подходят для отдыха.
        </p>
        <p className="text_p">
          Тип отпуска: Определитесь, хотите ли вы пляжный, экскурсионный или активный отдых.
        </p>
        <p className="text_p">
          Бюджет: Определите, сколько вы готовы потратить на тур и выбирайте соответствующие варианты. Также при планировании туров на школьные каникулы учитывайте, что они, как правило, дороже смежных дат.
        </p>
        <p className="text_p">
          Надеемся, вы найдете тур мечты на нашем сайте! Затем собирайте чемоданы и готовьтесь к незабываемому путешествию!
        </p>
      </div>
    </div>
  );

  render() {
    const text = this.getText();
    return (
      <div className="content">
        <Helmet>
          <title>{TURKEY_TITLE}</title>
          <meta name="description" content={TURKEY_DESCRIPTION} />

          <meta property="og:site_name" content="TourHunter.by" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://tourhunter.by/turkey/" />
          <meta property="og:title" content={TURKEY_TITLE} />
          <meta property="og:description" content={TURKEY_DESCRIPTION} />
          <meta property="og:image" content="https://tourhunter.by/file/turkey.jpg" />

          <link rel="canonical" href="https://tourhunter.by/turkey/" />
        </Helmet>
        <ToursResultFunc countryFilter={TURKEY_ID} text={text} />
      </div>
    );
  }
}

export default Turkey;
