/* eslint-disable no-trailing-spaces */
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Helmet } from 'react-helmet';
import { FAQ_TITLE, FAQ_DESCRIPTION } from '../../Utils/Constants/SEO';
import './css/faq.css';

class FAQ extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="content">
        <Helmet>
          <title>{FAQ_TITLE}</title>
          <meta name="description" content={FAQ_DESCRIPTION} />

          <meta property="og:site_name" content="TourHunter.by" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://tourhunter.by/faq/" />
          <meta property="og:title" content={FAQ_TITLE} />
          <meta property="og:description" content={FAQ_DESCRIPTION} />
          <meta property="og:image" content="https://tourhunter.by/file/thr_logo.png" />

          <link rel="canonical" href="https://tourhunter.by/faq/" />
        </Helmet>
        <div className="faq_intro">
          {/* eslint-disable-next-line react/self-closing-comp */}
          <div className="faq_intro__wave"></div>
        </div>
        <div className="container">
          <div>
            <h1 className="faq_page_title">Вопросы и ответы</h1>
            <div className="faq_question_w">
              <p className="faq_title">Что входит в состав тура?</p>
              <p className="faq_answer">В стоимость тура на нашем сайте включен перелет и отель, возможны трансфер и страховка.</p>
            </div>
            <div className="faq_question_w">
              <p className="faq_title">Оплата картой — это безопасно?</p>
              <p className="faq_answer">Безопасность совершения платежа обеспечивается современными методами проверки, шифрования и передачи данных по закрытым каналам связи. Ввод данных карточки осуществляется на защищенной авторизационной странице банка. Банк не передает данные Вашей карты магазину и иным третьим лицам!</p>
            </div>
            <div className="faq_question_w">
              <p className="faq_title">Как зарегистрироваться на сайте Tourhunter.by?</p>
              <p className="faq_answer">Зарегистрироваться можно, используя электронную почту.</p>
              <p className="faq_answer">Важно при оформлении заказа указать актуальный адрес электронной почты: именно через нее вы будете получать все уведомления о заказе, чтобы не пропустить важную информацию о перелёте, отеле и других деталях поездки.</p>
            </div>
            <div className="faq_question_w">
              <p className="faq_title">Как получить договор и другие документы?</p>
              <p className="faq_answer">Договор-оферта доступна на сайте, с ней можно ознакомится до  оформления заказа. Чтобы забронировать тур, нужно подтвердить согласие с условиями договора на странице бронирования.</p>
              <p className="faq_answer">Остальные документы, которые понадобятся в путешествии — ваучеры, билеты, страховка, — появляются за день-два до вылета в вашем Личном кабинете и на электронной почте. Обязательно проверьте все личные данные в документах и сообщите нам, если заметите ошибку.</p>
            </div>
            <div className="faq_question_w">
              <p className="faq_title">С какими туроператорами вы работаете?</p>
              <p className="faq_answer">Tourhunter.by работает со всеми крупными и надёжными белорусскими туроператорами: Fun&Sun, TezTour, «Библио-Глобус», Вояжтур, Anex tour,  Аэротревел, Тайм вояж, Pegas Touristik и другими. Режим отбора в базу Tourhunter.by строгий: мы оцениваем, как долго туроператор находится на рынке, проверяем финансовые показатели, отзывы туристов и степень ответственности в договорах.</p>
            </div>
            <div className="faq_question_w">
              <p className="faq_title">Как отменить тур?</p>
              <p className="faq_answer">Напишите нам в Telegram или электронную почту  tourhunter.by@gmail.com— мы постараемся оперативно помочь.</p>
              <p className="faq_answer">После того, как мы получим ваш запрос на отмену,  начнём уточнять условия у туроператора. Как правило, он отвечает в течение рабочего дня, но иногда чуть дольше. Если запрос поступил в выходной день, мы ответим только в понедельник. Напомним, что за такую отмену из стоимости тура удерживаются фактически понесенные затраты. Это сумма, которую туроператор выплачивает поставщикам, отменяя уже подтвержденное бронирование. У туроператора есть контракты с авиакомпаниями и отелями, и суммы выплат прописаны в этих контрактах, так что ни мы, ни туроператор не можем повлиять на их размер.</p>
            </div>
            <div className="faq_question_w">
              <p className="faq_title">Я отказываюсь от тура. В течение какого времени вернутся деньги?</p>
              <p className="faq_answer">Обычно туроператор возвращает деньги в течение нескольких недель. Иногда возврат занимает больше времени, если иностранные партнёры, которые не подчиняются белорусскому законодательству, будут задерживать возврат средств туроператору. Мы возвращаем деньги в течение пяти рабочих дней с того момента, как получаем их от туроператора, и делаем всё возможное, чтобы ускорить процесс.</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FAQ;
